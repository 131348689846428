import React, { Component } from "react";
import { HashRouter, BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const AuthLayout = React.lazy(() => import("./containers/AuthLayout"));

// Pages
// const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ResetPassword = React.lazy(() =>
  import("./views/pages/ResetPassword/ResetPassword")
);

const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPassword")
);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <AuthLayout {...props} />}
            />
            {/* <Login />
            </Route> */}
            <Route exact path="/register" name="Register Page">
              <Register />
            </Route>
            <Route
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />

            <Route
              exact
              path="/passwordReset"
              name="passwordReset"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/forgot-passwrod"
              name="ForgotPassword"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
